var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"RKDSICEp8YE6vRUTMIvaZ"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { Breadcrumb } from "@sentry/nextjs";
import { withoutGraphQLFetch } from "apollo-link-sentry";

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN_URL,
  release: process.env.SENTRY_RELEASE,
  environment: process.env.NEXT_PUBLIC_ENV,
  sampleRate: parseFloat(process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE || "0"),
  tracesSampleRate: parseFloat(process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE || "0"),
  enabled: process.env.NEXT_PUBLIC_ENV !== "test",

  // Enable source maps
  attachStacktrace: true,
  // Enable source maps for performance monitoring
  enableTracing: true,
  // Automatically instrument your code for performance monitoring
  autoSessionTracking: true,

  // Serialize nested javascript objects https://github.com/getsentry/sentry-javascript/issues/1964#issuecomment-625353135
  normalizeDepth: 10,
  initialScope: {
    tags: { next_service: "client" },
  },
  beforeBreadcrumb: withoutGraphQLFetch((breadcrumb: Breadcrumb) => {
    return breadcrumb.category &&
      (["xhr", "graphql.query", "graphql.mutation", "fetch"].includes(breadcrumb.category) ||
        breadcrumb.category.startsWith("ui"))
      ? breadcrumb
      : null;
  }),
  //before sending event, check if it has the tag overrideFrontendLog to prevent sending unused events
  beforeSend: (event) => {
    // Only allow events with specific tags
    if (event?.tags?.crash_frontend === "true" || event?.tags?.overrideFrontendLog === "true") {
      return event;
    }

    // Filter out all other events
    return null;
  },
  // Common unactionable errors from browser plugins and 3rd party script errors. https://gist.github.com/impressiver/5092952
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /satic\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  debug: process.env.NEXT_PUBLIC_ENV === "development", // we don't want to see sentry logs in production
  integrations: [
    Sentry.browserTracingIntegration({
      enableInp: true,
    }),
  ],
});
